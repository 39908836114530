import { Routes } from '@angular/router';

export const routes: Routes = [

    {
        path: '',
        redirectTo: 'public',
        pathMatch: 'full'
    },
    {
        path: 'public',
        loadChildren: () => import('src/app/pages/public/public-zone-entrypoint.module').then(m => m.PublicZoneEntrypointModule)
    },
    {
        path: 'main',
        loadChildren: () => import('src/app/pages/standard/main-zone-entrypoint.module').then(m => m.MainZoneEntrypointModule)
    },
    {
        path: 'alerts',
        redirectTo: 'public/alerts'
    },
    {
        path: '**',
        redirectTo: 'public'
    }

];
