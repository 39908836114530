import { Injectable } from '@angular/core';
import { CommentsOnly, Permissions } from 'ckeditor5-premium-features';
import { EditorPermissionConfig } from '../../models/editor-permission-config';
import { DecoupledEditor } from 'ckeditor5';
import { InputToolbarPlugin } from '../../custom-plugins/plugins/input/input-toolbar-plugin';

@Injectable({
  providedIn: 'root'
})
export class EditorPermissionConfigurationService {

    private READ_ONLY_GENERAL_LOCK_ID = '5e934910-8ba5-44d7-ae5b-94069d43d9cf';
    private READ_ONLY_EXTERNAL_LOCK_ID = 'a57fd34d-dd1d-46a4-8095-1c0f58acaa86';
    private PERMISSION_LOCK_ID = 'a57fd34d-dd1d-46a4-8095-1c0f58acaa87';

    public setPermission(editorInstance: DecoupledEditor, permissions: EditorPermissionConfig, isReadOnly: boolean): EditorPermissionConfig {
        if (!permissions) {
            return;
        }

        const isPermissionsReadOnly = !permissions.canEdit && !permissions.canComment && !permissions.hasCommentManagement && !permissions.canFill;
        this.setGeneralReadOnly(editorInstance, isPermissionsReadOnly, isReadOnly);
        this.setExternalReadOnly(editorInstance, isReadOnly);

        this.setCommentsPermissions(editorInstance, permissions);
        this.setInputPermissions(editorInstance, permissions);

        return JSON.parse(JSON.stringify(permissions));
    }

    public setExternalReadOnly(editorInstance: DecoupledEditor, isReadOnly: boolean): void {
        if (!editorInstance) {
            return
        }

        isReadOnly ? editorInstance.enableReadOnlyMode(this.READ_ONLY_EXTERNAL_LOCK_ID) : editorInstance.disableReadOnlyMode(this.READ_ONLY_EXTERNAL_LOCK_ID);
    }

    private setCommentsPermissions(editorInstance: DecoupledEditor, permissions: EditorPermissionConfig) {
        if(!editorInstance || !(editorInstance.plugins.has('CommentsOnly'))) {
            return;
        }

        const commentsOnlyPlugin = (editorInstance.plugins.get('CommentsOnly') as CommentsOnly);
        const canOnlyComment = !(permissions.canEdit || permissions.canFill) && permissions.canComment;

        canOnlyComment ? commentsOnlyPlugin.isEnabled = true : commentsOnlyPlugin.isEnabled = false;

        if (!editorInstance?.plugins.has( 'Permissions' )) {
            return;
        }

        const permissionsPlugin = editorInstance?.plugins.get( 'Permissions' );
        const permissionsForPlugin = [];
        if(permissions.canEdit || permissions.canFill) {
            permissionsForPlugin.push('document:write');
        }

        if (permissions.hasCommentManagement) {
            permissionsForPlugin.push('comment:admin');
            permissionsForPlugin.push('comment:write');
            permissionsForPlugin.push('comment:modify_all');
        } else if(permissions.canComment) {
            permissionsForPlugin.push('comment:write');
        } else {
            permissionsForPlugin.push('comment:read');
        }

        (permissionsPlugin as Permissions).setPermissions(permissionsForPlugin);
    }

    private setGeneralReadOnly(editorInstance: DecoupledEditor, isPermissionsReadOnly: boolean, isConfiguredReadonly: boolean): void {
        if (!editorInstance) {
            return;
        }
        const isReadOnly = isPermissionsReadOnly || isConfiguredReadonly;

        isReadOnly ? editorInstance.enableReadOnlyMode(this.READ_ONLY_GENERAL_LOCK_ID) : editorInstance.disableReadOnlyMode(this.READ_ONLY_GENERAL_LOCK_ID);
    }

    private setInputPermissions(editorInstance: DecoupledEditor, permissions: EditorPermissionConfig) {
        if(!editorInstance || !(editorInstance.plugins.has(InputToolbarPlugin.PLUGIN_NAME))) {
            return;
        }

        permissions.canEdit ?
            editorInstance.commands.get(InputToolbarPlugin.SHOW_INPUTS_COMMAND).clearForceDisabled(this.PERMISSION_LOCK_ID) :
            editorInstance.commands.get(InputToolbarPlugin.SHOW_INPUTS_COMMAND).forceDisabled(this.PERMISSION_LOCK_ID);      
    }
}
