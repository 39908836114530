import { View, Locale, addToolbarToDropdown, createDropdown, DropdownView, EventInfo } from 'ckeditor5';
import { Directive, Inject } from '@angular/core';
import { DropdownViewModel } from './row-dropdown-view.model';
import RowDropdownView from './row-dropdown-view.directive';

@Directive({
    selector: 'app-dropdown-view',
})
export default class CtboxDropdownView extends View {

    public dropDownToolbar: DropdownView;
    private readonly model: DropdownViewModel;
    private rowDropdownViews: RowDropdownView[] = [];
    private TOOLTIP_NORTH_POSITION = 'n';

    constructor(
        @Inject(Locale) locale: Locale,
        @Inject(DropdownViewModel) model: DropdownViewModel
    ) {
        super(locale);

        this.model = model;

        this.setTemplate({
            tag: 'div',
            attributes: {
                class: 'dropdown-container'
            },
            children: this.createRowDropdownViews()
        });

        this.dropDownToolbar = createDropdown(locale);
        this.dropDownToolbar.buttonView.set({
            label: model.titleText,
            withText: false,
            icon: model.iconSvg,
            tooltip: model.helpText,
            tooltipPosition: this.TOOLTIP_NORTH_POSITION
        });

        const buttonsInDropdown = [this];

        addToolbarToDropdown(this.dropDownToolbar, buttonsInDropdown, { isVertical: true });

        this.render();
    }

    public get dropdown(): DropdownView {
        return this.dropDownToolbar
    }

    public set isEnabled(value: boolean) {
        this.dropDownToolbar.isEnabled = value;
    }
   
    private createRowDropdownViews(): View[] {
        const views: View[] = [];

        for (const rowModel of this.model.buttonRowDropdownViewModel) {
            const rowView = new RowDropdownView(this.locale, rowModel);

            rowView.on('rowDropdownClicked', (name: EventInfo) => {
                this.handleRowDropdownClick(name);
            });

            views.push(rowView);
            this.rowDropdownViews.push(rowView);
        }

        return views;
    }

    private handleRowDropdownClick(name: EventInfo): void {
        this.fire('dropdownClicked', name);
    }
}
