export const environment = {
    production: false,
    apiBaseURL: 'https://contractbox-api-qa.aranzadilaley.es',
    corporateWebsiteUrl: "https://aranzadilaley-qa.internalnaw.es/productos/contractbox.html?retURL=https://miespacio.aranzadilaley.es/Account/Login?returnUrl={0}",
    myAccountExternalLoginUrl: 'https://miespacio.aranzadilaley.es/Account/MyAccountExternalLoginWithoutRegister',
    myAccountExternalLoginUrlWithRegister: 'https://miespacio.aranzadilaley.es/Account/MyAccountExternalLogin',
    myAccountLogoutUrl: 'https://miespacio.aranzadilaley.es/Account/LogOff',
    myAccountExternalLink: 'https://miespacio.aranzadilaley.laleynext.es',
    myAccountOpenMyLicenses: 'https://miespacio.aranzadilaley.es/Licenses/LicensesList#/user-list',
    laleyUrl: 'https://www.laley.es/',
    laleydigitalUrl: 'https://laleydigital-qa.laleynext.es/Content/Formularios.aspx',
    laleydigitalLanding: 'https://laleydigital-qa.laleynext.es/Content/Inicio.aspx',
    laleydigitalAllContent: 'https://laleydigital-qa.laleynext.es/Content/Busqueda.aspx?params=H4sIAAAAAAAEAMssdiwoKMovS02xNTIwMjSwNDI2NDY0BQAfePjdFwAAAA==WKE',
    laleydigitalDocument: 'https://laleydigital.laleynext.es/Content/Documento.aspx',
    lldUrl: 'https://laleydigital.laleynext.es',
    aranzadiUrl: 'https://soluciones.aranzadilaley.es',
    eloquaformURL: 'https://wke-qa.wkcols.com/forms/uniform/form.php?product=111&utm_source=Producto Contractbox&utm_campaign=Visit',
    vimeoURL: 'https://player.vimeo.com/video/',
    LLDTemplatesTC: 'TC0000041380',
    clientId: 'Standard',
    maxFilesInFolderToExport: 10,
    myAccountLoginSamlUrl: '',
    clientUrl: 'https://contractbox-qa.aranzadilaley.es/es',
    showChatButton: false,
    showBrand: true,
    editorLicenseKey: 'OU9weVN6NzRrK0lGY05GWVZnSnNsWDhZUmtqMGkxV2g4QUl6TmlhZENEZkI5bWo4UXZxTDdFamZJNXN0WUE9PS1NakF5TlRFd01UQT0=',
    suggestRequestDebounceTime: 150
};
