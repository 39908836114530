import { Directive, Injectable, OnDestroy } from "@angular/core";
import { ContextualBalloon, Editor, Widget, ClickObserver, ViewDocumentClickEvent, ViewDocumentKeyDownEvent, EventInfo, KeyEventData, ViewNode, ViewDocumentFragment } from "ckeditor5";
import { ClauseModel } from "../../models/clause/clause-model";
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { GenericDialogConfig } from "src/app/core/shared/models/generic-dialog-config.model";
import { dialogTypes } from "../../../../ctbox-generic-dialog-data/ctbox-generic-dialog-data.component";
import { ITransformCleanHtmlService } from "src/app/core/shared/services/html/transform-clean-html/transform-clean-html.service.interface";
import { IClausesNavigationService } from 'src/app/core/standard/services/clauses/clause-navigation/clauses-navigation.service.interface';
import ClauseAddCommand from "../../commands/clause/clauses-add-command";
import ClauseDeleteCommand from "../../commands/clause/clauses-delete-command";
import ClauseGoToCommand from "../../commands/clause/clauses-go-to-command";
import { BasePlugin } from "../base/base-plugin";
import ClauseBalloonView from '../../ui/clause/clause-balloon-view.directive';
import { ClauseSchemaService } from "../../schema/clause/clause-schema.service";
import { UserInterfaceService } from "../../ui/user-interface.service";
import { ToolbarButtonModel } from '../../models/base/toolbar-button-model';
import { ClauseModelToDataViewConverterService } from '../../converters/clause/clause-model-to-data-view-converter.service';
import { ClauseModelToEditorViewConverterService } from '../../converters/clause/clause-model-to-editor-view-converter.service';
import { ClauseDataViewToModelConverterService } from '../../converters/clause/clause-data-view-to-model-converter.service';
import { UI_CLASSES } from "../../ui/styles/styles-constants";
import { GlobalConstant } from "../../models/base/global-constant";
import { ClausesTreeTextComponent } from "src/app/pages/standard/clauses-library/clauses-tree-text/clauses-tree-text.component";
import { ClauseDto } from "src/app/api";
import { IHtmlEditorInputProcessor } from "src/app/core/shared/services/html/html-editor-input-processor/html-editor-input-processor.interface";
import { SpecialKeyCode } from "../../models/base/keycode";
import { InputPlugin } from "../input/input-plugin";
import { ClauseService } from "src/app/api/api/standard/clause.service";

@Directive({
    selector: 'clauses-plugin',
})
@Injectable({
    providedIn: 'root'
})
export class ClausesPlugin extends BasePlugin implements OnDestroy {

    public static readonly PLUGIN_NAME = "Clauses";
    public static readonly EMBEDDED_CLAUSE = "embedded-clause";
    public static readonly CLAUSE_IN_EDITOR = "clause-in-editor";
    public static readonly DELETE_COMMAND_NAME = "delete-clause";
    public static readonly GO_TO_COMMAND_NAME = "go-to-clause";
    public static readonly INSERT_COMMAND_NAME = "add-embedded-clause";
    public static readonly ID = 'id';
    public static readonly DESCRIPTION = 'description';
    public static readonly TOOLBAR_NAME_STRING = $localize`:@@ClausulaBotonera:Cláusula`;
    public static readonly TOOLBAR_TOOLTIP = $localize`:@@InsertarClausulaEtiquetaBotonBotonera:Insertar una cláusula de la biblioteca`;
    public static readonly DELETE_OPTION = $localize`:@@BorrarClausulaEmbebida:BORRAR`;
    public static readonly GO_TO_CLAUSE = $localize`:@@IrAClausulaEmbebida:IR A CLAUSULA`;
    public static readonly ENABLE_DISABLE_LOCKID = '1c29d38f-78aa-416b-ba6b-c550e595d658';

    public static readonly MODEL_ENTITIES = {
        'class' :   {
            model: ClausesPlugin.EMBEDDED_CLAUSE,
            dataView: ClausesPlugin.CLAUSE_IN_EDITOR,
            editionView: ClausesPlugin.CLAUSE_IN_EDITOR
        },
    };

    public static genericDialog: GenericDialogService;
    public static cleanHtmlService: ITransformCleanHtmlService;
    public static clausesNavigationService: IClausesNavigationService;
    public static htmlInputProcessor: IHtmlEditorInputProcessor;
    public static clauseService: ClauseService;

    private clauseSchemaService: ClauseSchemaService;

    private dataViewToModelConverter: ClauseDataViewToModelConverterService;
    private modelToDataViewConverter: ClauseModelToDataViewConverterService;
    private modelToEditorViewConverter: ClauseModelToEditorViewConverterService;

    private userInterfaceService: UserInterfaceService;
    private balloonView: ClauseBalloonView;
    private lastDialog: MatDialogRef<any, any>;

    protected mappers = [
        ClausesPlugin.MODEL_ENTITIES.class.dataView
    ];

    protected commands = {
        [ClausesPlugin.INSERT_COMMAND_NAME]: ClauseAddCommand,
        [ClausesPlugin.DELETE_COMMAND_NAME]: ClauseDeleteCommand,
        [ClausesPlugin.GO_TO_COMMAND_NAME]: ClauseGoToCommand
    };

    protected toolbarButton: ToolbarButtonModel = {
        icon: UI_CLASSES.SVG_ICONS.CLAUSE_EDITOR_ICON,
        pluginToolbarElementName: ClausesPlugin.pluginToolbarElementName,
        buttonText: ClausesPlugin.TOOLBAR_NAME_STRING,
        tooltip: ClausesPlugin.TOOLBAR_TOOLTIP,
        hasTooltip: true,
        hasText: true
    };

    constructor(editor: Editor) {
        super(editor);
        this.dataViewToModelConverter = new ClauseDataViewToModelConverterService();
        this.modelToDataViewConverter = new ClauseModelToDataViewConverterService();
        this.modelToEditorViewConverter = new ClauseModelToEditorViewConverterService();
        this.clauseSchemaService = new ClauseSchemaService();
        this.userInterfaceService = new UserInterfaceService();
    }

    public static get requires() { return [Widget, ContextualBalloon]; }
    public static get pluginName() { return ClausesPlugin.PLUGIN_NAME; }
    public static get pluginToolbarElementName() { return ClausesPlugin.EMBEDDED_CLAUSE; }
    public static get visualSelectionMarker() { return ClausesPlugin.EMBEDDED_CLAUSE; }

    protected defineSchema(): void {
        this.clauseSchemaService.defineSchema(this.schema);
    }

    protected defineConverters(): void {
        this.modelToEditorViewConverter.configureConverters(this.conversion);
        this.dataViewToModelConverter.configureConverters(this.conversion);
        this.modelToDataViewConverter.configureConverters(this.conversion);
    }

    protected editorInteractions(): void {
        super.setupEditorObserver(ClickObserver);
        this.enableBalloonActivators();
        this.disableToolbarButton(ClausesPlugin.INSERT_COMMAND_NAME);
    }

    protected override toolbarExecuteOperation(): void {
        this.openInsertClauseFromLibrary();
    }

    private enableBalloonActivators(): void {
        const viewDocument = this.editor.editing.view.document;
        this.listenTo<ViewDocumentClickEvent>(viewDocument, 'click', this.handleClickEvent.bind(this));
        this.listenTo<ViewDocumentKeyDownEvent>(viewDocument, 'keydown', this.handleKeyDownEvent.bind(this), { priority: 'highest' });
    }

    private handleClickEvent(): void {
        if (this.isReadOnlyOrRestrictedMode()) {
            return;
        }

        const parentClause = this.pluginUtils.getSelectedContainerWithClass(this.editor, ClausesPlugin.MODEL_ENTITIES.class.editionView);
        if (!parentClause) {
            return;
        }
        const idClause = parentClause.getAttribute(GlobalConstant.ATTRIBUTE_ID);
        this.showUI(idClause);
    }

    private showUI(idClauseValue?: string): void {
        if (!this.balloonView) {
            this.createBalloonView(idClauseValue);
        }

        this.userInterfaceService.addUI(this.editor, this.balloon, this.balloonView, ClausesPlugin.MODEL_ENTITIES.class.editionView, ClausesPlugin.visualSelectionMarker);
    }

    private createBalloonView(idClauseValue?: string) {
        this.balloonView = this.getBalloonView(idClauseValue);
        this.userInterfaceService.enableUserBalloonInteractions(this.editor, this.balloon, this.balloonView,
            ClausesPlugin.visualSelectionMarker, this);
    }

    private getBalloonView(idClauseValue?: string): ClauseBalloonView {

        const balloonView = new ClauseBalloonView(this.editor.locale);

        this.configureDeleteClauseButton(balloonView);
        this.configureGoToClauseButton(balloonView, idClauseValue!);

        return balloonView;
    }

    private configureDeleteClauseButton(balloonView: ClauseBalloonView) {
        balloonView.deleteButtonView.on('execute', () => {
            this.editor.execute(ClausesPlugin.DELETE_COMMAND_NAME);
            this.hideUI();
        });
    }

    private configureGoToClauseButton(balloonView: ClauseBalloonView, idClauseValue: string) {
        if (!idClauseValue || !idClauseValue.includes('id_')) {
            return ;
        }
        const [, guid] = idClauseValue.split('_');
        ClausesPlugin.clauseService.exists(guid).subscribe(clauseExist => {
            if (clauseExist.exists) {
                balloonView.goToClauseButtonView.on('execute', () => {
                    this.editor.execute(ClausesPlugin.GO_TO_COMMAND_NAME, idClauseValue);
                    this.hideUI();
                });
            } else {
                balloonView.goToClauseButtonView.on('execute', () => {
                    this.openClauseAlreadyDeletedMessage();
                    this.hideUI();
                });
            }
        });
    }

    private openInsertClauseFromLibrary(): void {
        const config = this.getListModalConfig();
        const data = this.getDialogData();

        this.lastDialog = ClausesPlugin.genericDialog.openTemplateWithConfigAndData(data.template, config, data);
        this.hideUI();
        this.lastDialog.afterClosed().subscribe((result: ClauseDto[]) => {

            if (result === null) {
                return;
            }
            this.insertClauses(result);
        });
    }

    private openClauseAlreadyDeletedMessage(): void {
        const error = $localize`:@@ErrorClausulaYaBorrada:La cláusula ha sido eliminada de la biblioteca. Se está procediendo a desvincularla de la plantilla.`;
        ClausesPlugin.genericDialog.showMessage(error);
        return;
    }

    private insertClauses(clausesArray: ClauseDto[]): void {
        clausesArray.forEach(clause => this.insertClause(clause));
    }

    private insertClause(clauseInfo: ClauseDto): void {
        let html = ClausesPlugin.cleanHtmlService.cleanHeader(clauseInfo.description);
        html = ClausesPlugin.htmlInputProcessor.removeControlTags(html);
        html = ClausesPlugin.cleanHtmlService.cleanHelpNotes(html);
        const clause: ClauseModel = { id: clauseInfo.id, description: html };

        this.editor.execute(ClausesPlugin.INSERT_COMMAND_NAME, clause);
    }

    private getListModalConfig(): GenericDialogConfig {
        const config = ClausesPlugin.genericDialog.getDefaultDialogConfig();
        config.width = UI_CLASSES.SIZE.WIDTH_CLAUSE_MODAL;
        config.height = UI_CLASSES.SIZE.HEIGHT_CLAUSE_MODAL;
        return config;
    }

    private getDialogData(): any {
        return {
            template: ClausesTreeTextComponent,
            displayCloseOption: true,
            displayButtonBar: true,
            dialogButton: $localize`:@@InsertarClausulaDesdeBibliotecaBoton:Insertar seleccionados`,
            dialogCloseButon: $localize`:@@Cancelar:Cancelar`,
            dialogTypes: dialogTypes.NotOverflow,
            dialogTitle: $localize`:@@InsertarClausulaDesdeBiblioteca:Insertar una cláusula desde la biblioteca`,
            primaryButtonContentObservableName: 'isValidSubscription',
            primaryButtonActionBeforeCloseObservableName: 'actionBeforeCloseFinishHim',
            primaryButtonActionAfterCloseObservableName: 'actionAfterCloseFinishHim',
            dialogContentGetResultPropertyName: 'selectedClauses',
            dialogContentGetCloseResultPropertyName: 'selectedClauses',
        };
    }

    private hideUI() : void {
        if (!this.userInterfaceService.isBalloonInPanel(this.balloon, this.balloonView)) {
            return;
        }

        this.userInterfaceService.removeBalloonObservers(this.editor, this.balloon, this);
        this.editor.editing.view.focus();
        this.removeBalloonView();

        if (this.balloon.hasView(this.balloonView)) {
            this.balloon.remove(this.balloonView!);
        }

        this.pluginUtils.hideFakeVisualSelection(this.editor, ClausesPlugin.visualSelectionMarker);
    }

    private removeBalloonView(): void {
        if (!this.userInterfaceService.isBalloonInPanel(this.balloon, this.balloonView)) {
            return;
        }

        this.balloon.remove(this.balloonView!);
        this.editor.editing.view.focus();
        this.pluginUtils.hideFakeVisualSelection(this.editor, ClausesPlugin.visualSelectionMarker);
    }

    private handleKeyDownEvent(event: EventInfo<'keydown'>, data: KeyEventData): void {
        if (this.isCommentsOnlyMode()) {
            return;
        }

        const isKeyGetOutClause = data.keyCode === SpecialKeyCode.TAB || data.keyCode === SpecialKeyCode.ESCAPE;
        if (isKeyGetOutClause) {
            return;
        }

        const parentClause = this.pluginUtils.getSelectedElementWithClass(this.editor, ClausesPlugin.MODEL_ENTITIES.class.editionView);
        const idParentClause = parentClause?.getAttribute(GlobalConstant.ATTRIBUTE_ID);
        const selectedElement = this.pluginUtils.getSelectedElement(this.editor.editing.view.document.selection);
        const idSelectedElement = selectedElement.is("view:element") ? selectedElement?.getAttribute(GlobalConstant.ATTRIBUTE_ID) : '';

        const isParentClauseSelected = idParentClause === idSelectedElement;

        if (!this.isEditableInput(selectedElement) && parentClause && !isParentClauseSelected) {
           this.finishEventPropagation(data, event);
           return;
        }

        const isClauseSelected = !!parentClause && selectedElement === parentClause;
        if (isClauseSelected) {
            this.finishEventPropagation(data, event);
            return;
        }
    }

    private isEditableInput(selectedElement: ViewNode | ViewDocumentFragment | null): boolean {
        return (selectedElement?.parent?.is("view:element") && InputPlugin.isInputPlugin(selectedElement.parent)) ||
        (selectedElement?.parent?.parent?.is("view:element") && InputPlugin.isInputPlugin(selectedElement.parent.parent))
    }
}
