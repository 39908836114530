import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RouteStateService {

    private pathParamForceLoginState = new BehaviorSubject<string>('');
    private pathParamReturnUrlState = new BehaviorSubject<string>('');
    private pathParamEmailState = new BehaviorSubject<string>('');
    
    public pathParamForceLogin: Observable<string>;
    public pathParamReturnUrl: Observable<string>;
    public pathParamEmail: Observable<string>;

    constructor() {
        this.pathParamForceLogin = this.pathParamForceLoginState.asObservable();
        this.pathParamReturnUrl = this.pathParamReturnUrlState.asObservable();
        this.pathParamEmail = this.pathParamEmailState.asObservable();
    }

    public updateParamForceLoginState(newForceLogin: string): void {
        this.pathParamForceLoginState.next(newForceLogin);
    }

    public updateParamReturnUrl(newParamUrl: string): void {
        this.pathParamReturnUrlState.next(newParamUrl);
    }

    public updateParamEmail(newEmail): void {
        this.pathParamEmailState.next(newEmail);
    }

    public getParamReturnUrl(): string {
        return JSON.parse(JSON.stringify(this.pathParamReturnUrlState.getValue()));
    }
}
