import { Component, Input, OnInit, ElementRef, ViewChild, ViewEncapsulation, OnDestroy, EventEmitter } from '@angular/core';
import { UserInfoDTO } from 'src/app/api';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthorizeService } from 'src/app/core/shared/services/authorize/authorize.service';
import { Router } from '@angular/router';
import { RouteStateService } from 'src/app/core/shared/services/route-state/route-state.service';
import { PlatformLocation } from '@angular/common';
import { CustomizeConfiguration } from 'src/config/customisation';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-login-status',
    templateUrl: './login-status.component.html',
    styleUrls: ['./login-status.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoginStatusComponent implements OnInit, OnDestroy {

    @ViewChild('modalLoginForm') modalLoginForm: ElementRef;

    @Input() showAccessButton: boolean;

    public canManageLicenses = false;

    public loginStatusUserInfo = new BehaviorSubject<UserInfoDTO>(null);

    public isAdmin = false;

    private userInfoRefreshedOnceObservable: BehaviorSubject<boolean>;
    private forceLoginObservable: Subject<boolean> = new Subject<boolean>();

    private forceLogin = false;
    private callBackUrl: string = null;

    private onDestroy$ = new EventEmitter<void>();

    constructor(
        private readonly loginService: AuthorizeService,
        private readonly router: Router,
        private readonly routeStateService: RouteStateService,
        private readonly platformLocation: PlatformLocation,
        private readonly customisation: CustomizeConfiguration
    ) {
        this.userInfoRefreshedOnceObservable = this.loginService.getUserInfoRefreshedOnce();
    }

    public ngOnInit(): void {

        this.forceLoginObservable
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((value: boolean) => {
                if (!value) {
                    return;
                }

                this.goToMyAccountLogin();
                this.forceLogin = false;
            });


        this.routeStateService.pathParamForceLogin
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(value => {
                this.forceLogin = (value === 'true');
                this.sendNotificationOpenMyAccountWithUrl();
            });

        this.routeStateService.pathParamReturnUrl
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(value => {
                this.callBackUrl = value;
                this.sendNotificationOpenMyAccountWithUrl();
            });

        this.subscribeToUserChanges();
        this.loginService.refreshUserInfo();

        if (this.forceLogin && !this.loginService.getUserInfoRefreshingValue()) {
            this.goToMyAccountLogin();
        }

        this.canManageLicenses = this.customisation.customizeIdName === 'LALEY';
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    public hasUserInfoValue(): boolean {
        const userInfo: UserInfoDTO = this.loginStatusUserInfo.getValue();
        return !!userInfo;
    }

    public subscribeToUserChanges(): void {
        this.loginStatusUserInfo
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((value: UserInfoDTO) => {
                if (!this.userInfoRefreshedOnceObservable.getValue()) {
                    return;
                }

                let urlToken: string[] = [];
                if(!!(this.platformLocation as any)?.location)  {
                urlToken = (this.platformLocation as any)?.location.pathname.split('/');
                } else {
                    urlToken = (this.platformLocation as any)?.pathname.split('/');
                }
                const isCurrentUrlPublicLanding = urlToken.length > 2 &&
                    urlToken[2] === 'public';

                if (value && !value.hasLicense && !isCurrentUrlPublicLanding) {
                    this.router.navigate(['main/home/no-license']);
                }
            });

        this.loginService.getUserInfo()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((user: UserInfoDTO) => {
                this.loginStatusUserInfo.next(user);
                this.isAdmin = user?.isAdmin;
            });
    }

    public goToMyAccountLogin(): void {
        const returnUrl = `${environment.clientUrl}${this.callBackUrl}`;
        window.location.href = environment.corporateWebsiteUrl.replace('{0}', returnUrl);
    }

    public openMyAccount(): void {
        window.open(environment.myAccountExternalLink);
    }

    public openMyLicenses(): void {
        window.open(environment.myAccountOpenMyLicenses);
    }

    public closeSession(): void {
        const returnUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/es';
        document.location.href = environment.myAccountLogoutUrl + '?returnUrl=' + returnUrl;
    }

    private sendNotificationOpenMyAccountWithUrl() {
        if (!!this.callBackUrl && this.forceLogin) {
            this.forceLoginObservable.next(true);
        }
    }
}
