import { Editor} from "ckeditor5";
import { InputBaseCommand } from "./input-base-command";

export default class ShowInputsCommand extends InputBaseCommand {

    constructor(editor: Editor){
        super(editor);
    }

    public override execute(): void {}      
}
