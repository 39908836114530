import { Directive, OnDestroy } from "@angular/core";
import { ContextualBalloon, Dialog, Editor, Widget, Element } from "ckeditor5";
import { BasePlugin } from "../base/base-plugin";
import { ToolbarButtonModel } from "../../models/base/toolbar-button-model";
import { PluginGlobalConstant } from "../../../models/plugin-global-constant";
import { GlobalConstant } from "../../models/base/global-constant";

@Directive({
    selector: "style-inheritance-plugin",
})
export class StyleInheritancePlugin extends BasePlugin implements OnDestroy {
    public static readonly PLUGIN_NAME = "style-inheritance-plugin";

    protected toolbarButton: ToolbarButtonModel = null;
    protected commands: { [key: string]: any; } = [];
    protected mappers: string[] = [];

    private readonly CHILD_TEXT_NODE = 0;

    constructor(editor: Editor) {
        super(editor);
    }

    public static get requires() { return [Widget, ContextualBalloon, Dialog]; }
    public static get pluginName(): string { return StyleInheritancePlugin.PLUGIN_NAME; }

    public init(): void {
        super.init();
        this.defineCustomListeners();
    }

    public override toolbarExecuteOperation(): void {}

    protected override toolbarInteractions(): void {}

    protected defineSchema(): void {}

    protected defineConverters(): void {}

    protected editorInteractions(): void {}

    private defineCustomListeners() {
        const modelDocument = this.editor.editing.model.document;
        this.listenTo(modelDocument, 'change', this.handleTextStyleInheritance.bind(this));
    }

    private handleTextStyleInheritance(): void {
        const modelDocument = this.editor.model.document;
        const selection = modelDocument.selection;

        if (selection.isCollapsed) {
            const position = selection.getFirstPosition();
            const element = position.parent as Element;

            if (this.isParagraphWithText(element) && !this.hasStyles(this.getChildTextNode(element))) {
                const previousElement = this.findStyledElementBefore(element);

                if (previousElement) {
                    const styles = this.extractStylesFromElement(previousElement);
                    this.applyStylesToElement(this.getChildTextNode(element), styles);
                }
            }
        }
    }

    private getChildTextNode(element: Element): Element {
        return element.getChild(this.CHILD_TEXT_NODE) as Element;
    }

    private isParagraphWithText(element: Element): boolean {
        return element.name === GlobalConstant.MODEL_PARAGRAPH && element.childCount > 0 && this.getChildTextNode(element).is(GlobalConstant.MODEL_TEXT);
    }

    private hasStyles(element: Element): boolean {
        const styleAttributes = PluginGlobalConstant.STYLE_ATTRIBUTES.map(attr => attr.model);
        const attributes = element.getAttributes();

        for (const [key] of attributes) {
            if (styleAttributes.includes(key)) {
                return true;
            }
        }

        return false;
    }

    private findStyledElementBefore(element: Element): Element | null {
        const position = this.editor.model.createPositionBefore(element);

        const walker = this.editor.model.createRangeIn(this.editor.model.document.getRoot()).getWalker({
            startPosition: position,
            direction: 'backward'
        });

        for (const { item } of walker) {
            if (item.is(GlobalConstant.ELEMENT)) {
                const childTextNode = this.getChildTextNode(item);

                if (childTextNode && childTextNode.is(GlobalConstant.MODEL_TEXT) && this.hasStyles(childTextNode)) {
                    return item;
                }
            }
        }

        return null;
    }

    private extractStylesFromElement(element: Element): { [key: string]: string } {
        const styleAttributes = PluginGlobalConstant.STYLE_ATTRIBUTES.map(attr => attr.model);
        const attributes = this.getChildTextNode(element).getAttributes();
        const styles: { [key: string]: string } = {};

        for (const [key, value] of attributes) {
            if (styleAttributes.includes(key)) {
                styles[key] = value.toString();
            }
        }

        return styles;
    }

    private applyStylesToElement(element: Element, styles: { [key: string]: string }): void {
        const model = this.editor.model;

        if (!model) {
            return;
        }

        model.change(writer => {
            for (const [key, value] of Object.entries(styles)) {
                writer.setAttribute(key, value, element);
            }
        });
    }
}
